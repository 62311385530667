import styled from "styled-components";

export const OptionBtn = styled.div(
  ({ justifyContent, marginTop, arabic, width, selected }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    gap: 4px;
    width: ${width ? width : "140px"};
    background: #E8E9F5B2;
    border-radius: 61px;
    cursor:pointer;
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    @media (max-width: 992px) {
      width: ${width ? width : "100%"} ;
    }
  `
);
