import React, { useEffect } from "react";
import { Button, Col, Container } from "react-bootstrap";
import { Header } from "../Component";
import CountryModal from "../Modal/CountryModal";
import * as countries from "../Constant/countries";
import { useLocation } from "react-router-dom";
import { ButtonComponent } from "../Component/ButtonComponent";
import { Con, TextCon, HeaderText, Subtext } from "./styled/careerTest";

export const CareerTest = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    // document.getElementById("html").style.overflow = "scroll";
  }, []);
  const router = useLocation();
  const query = router.search;

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <>
        <Header onClick={() => setModalShow(true)} />
        <Con>
          <div
            className="pc-view"
            style={{
              position: "absolute",
              left: 0,
              bottom: "0",
            }}
          >
            <img
              className="index-banner-img"
              src="https://cdn.ulearn.study/major-bnr.png"
              alt="img"
            />
          </div>
          <TextCon direction={"flex-end"} className="container">
            <HeaderText arabic={true}> اختبار تحديد التخصص</HeaderText>
            <div className={`d-flex  flex-column ${"align-items-end"}`}>
              <div>
                {" "}
                <Subtext style={{}} fontWeight={400} arabic={true}>
                  اختبار تحديد التخصص مع يوليرن هو أسرع طريقة لمعرفة التخصص{" "}
                  <br className="pc-view" />
                  الأنسب لك خلال 15 دقيقة فقط. الاختبار عبارة عن 30 سؤال. يرجى
                  <br className="pc-view" />
                  الإجابة على الأسئلة بكل صدق لتكون النتيجه مناسبة لك.
                  <br />
                  <br />
                  *يرجى العلم أن نتيجة هذا الاختبار تقريبية وليست حتمية
                </Subtext>
              </div>
              <div
                className={`flex-column container d-flex justify-content-center ${"align-items-end"} h-100`}
              >
                <ButtonComponent
                  style={{
                    marginTop: 29,
                    padding: "0 58px",
                    width: "90%",
                    zIndex: 99,
                    marginBottom: 30,
                  }}
                  width="100%"
                  className="pc-view"
                  label={"ابدأ الاختبار"}
                  loading={false}
                  btnType={"dark"}
                  onClick={() => setModalShow(true)}
                />
              </div>
            </div>
            <ButtonComponent
              style={{
                marginTop: 29,
                padding: "0 58px",
                width: "90%",
                zIndex: 99,
                marginBottom: 30,
              }}
              width="100%"
              className="res-home-btn mobile-view"
              label={"ابدأ الاختبار"}
              loading={false}
              btnType={"dark"}
              onClick={() => setModalShow(true)}
            />
          </TextCon>
          <div
            className="mobile-view"
            style={{ position: "relative", bottom: 25, zIndex: 99 }}
          >
            {/* <IndexIconMobile /> */}
            <img
              style={{ width: "100%" }}
              src={
                "https://firebasestorage.googleapis.com/v0/b/digital-vim-270607.appspot.com/o/major-bnr.png?alt=media&token=6190663b-a0ea-4b45-89d8-f71d882d90d7"
              }
              alt="img"
            />
          </div>
          {modalShow ? (
            <CountryModal
              countries={countries}
              show={modalShow}
              query={query}
              onHide={() => setModalShow(false)}
            />
          ) : null}
        </Con>
      </>
    </div>
  );
};
