import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Dropdown } from "react-bootstrap";
import ArrowDown from "../Assets/Images/ArrowDown";
import { useNavigate } from "react-router-dom";
import { CircleCross } from "../Assets/Images/CircleCross";
import { OptionBtn } from "./styled";

export default function CountryModal(props) {
  let history = useNavigate();
  const [destination, setdestination] = React.useState({});

  const onChangeDestination = (data) => {
    setdestination(data);
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="careerModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="w-100" style={{ borderBottom: "none" }}>
        <div
          className="d-flex justify-content-start w-100"
          style={{ cursor: "pointer" }}
          onClick={() => props.onHide()}
        >
          <CircleCross />
        </div>
      </Modal.Header>
      <Modal.Body style={{ overflow: "hidden" }}>
        <div className="country-modal-header ">
          <h4 style={{ textAlign: "center" }}>في أي بلد ترغب بالدراسة؟</h4>
        </div>
        <div
          style={{ width: "70%" }}
          className="d-flex flex-column justify-content-center w-100"
        >
          <div
            className="d-flex flex-row w-100 justify-content-center"
            style={{
              paddingBottom: 15,
              direction: "rtl",
            }}
          >
            <OptionBtn
              className="  "
              arabic={true}
              justifyContent="space-between"
              style={{
                color: destination.en_name === "UK" ? "#299cf7" : "#191d23",
                backgroundColor:
                  destination.en_name === "UK" ? "#299cf714" : "#E8E9F5B2",
                width: "100%",
                direction: "rtl",
                margin: "0px 0 0 20px",
              }}
              onClick={() =>
                onChangeDestination({
                  ar_name: "بريطانيا",
                  en_name: "UK",
                })
              }
            >
              <div />
              <div
                style={{
                  fontSize: 24,
                  position: "relative",
                  left: 10,
                }}
              >
                🇬🇧
              </div>{" "}
              بريطانيا
            </OptionBtn>
            <OptionBtn
              className="  "
              arabic={true}
              justifyContent="space-between"
              style={{
                color: destination.en_name === "US" ? "#299cf7" : "#191d23",
                backgroundColor:
                  destination.en_name === "US" ? "#299cf714" : "#E8E9F5B2",
                width: "100%",
                marginRight: 0,
              }}
              onClick={() =>
                onChangeDestination({ ar_name: "أمريكا", en_name: "US" })
              }
            >
              <div />
              <div
                style={{
                  fontSize: 24,
                  position: "relative",
                  left: 10,
                }}
              >
                🇺🇸
              </div>{" "}
              أمريكا
            </OptionBtn>
          </div>

          <div className="d-flex flex-row w-100 justify-content-center">
            <OptionBtn
              className="  "
              arabic={true}
              justifyContent="space-between"
              style={{
                color:
                  destination.en_name === "Australia" ? "#299cf7" : "#191d23",
                backgroundColor:
                  destination.en_name === "Australia"
                    ? "#299cf714"
                    : "#E8E9F5B2",
                width: "100%",
                direction: "rtl",
                margin: "0px 20px 0 0",
              }}
              onClick={() =>
                onChangeDestination({
                  ar_name: "أستراليا",
                  en_name: "Australia",
                })
              }
            >
              <div />
              <div
                style={{
                  fontSize: 24,
                  position: "relative",
                  left: 10,
                }}
              >
                🇦🇺
              </div>{" "}
              أستراليا
            </OptionBtn>

            <OptionBtn
              className="  "
              arabic={true}
              justifyContent="space-between"
              style={{
                color: destination.en_name === "Others" ? "#299cf7" : "#191d23",
                backgroundColor:
                  destination.en_name === "Others" ? "#299cf714" : "#E8E9F5B2",
                width: "100%",
                marginRight: 0,
                direction: "rtl",
              }}
              onClick={() =>
                onChangeDestination({
                  ar_name: "غيرها",
                  en_name: "Others",
                })
              }
            >
              <div />
              <div
                style={{
                  fontSize: 24,
                  position: "relative",
                  left: 10,
                }}
              >
                🌏
              </div>{" "}
              غيرها
            </OptionBtn>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        className="d-flex justify-content-start"
        style={{ border: "none" }}
      >
        <Button
          style={{ width: 158, marginTop: 0 }}
          className="custom-black-btn"
          disabled={!Object.keys(destination).length}
          onClick={() => {
            history(props.query ? `/major-test${props.query}` : "/major-test", {
              state: { country: destination.ar_name },
            });
          }}
        >
          ابدأ الاختبار
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
